var exports = {};
exports.__esModule = true;
exports.default = function (ref) {
  return {
    methods: {
      focus: function focus() {
        this.$refs[ref].focus();
      }
    }
  };
};
;
export default exports;
export const __esModule = exports.__esModule;